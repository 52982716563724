import axios from "axios";
import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Head from "next/head";
import Carousel from "../components/Carousel";
import MobileCrousel from "../components/MobileCrousel";
import PromiseCard from "../components/PromiseCard";
import { useRouter } from "next/router";
import { motion } from "framer-motion";
import QuestionAnswerTile from "../components/QuestionAnswerTile";
import NewTagCard from "../components/NewTagCard";
import MasterclassCard from "../components/MasterclassCard";
import Image from "next/image";
import BlogPhotoTile from "../components/BlogTilePhoto";
import Link from "next/link";
import webengage from "../webengage";
import useKey from "@/hooks/use-key";
import dayjs from "dayjs";
import mixpanel from "@/utils/mixpanel";
import { calculateReadingTime } from "./blogs";

const qNAs = [
  {
    que: "How can I sign up for a free masterclass?",
    ans: "You can sign up for our free masterclasses by visiting our website and registering with your phone number. We will send you all the details needed to join.",
  },
  {
    que: "Will I receive any materials or resources after the masterclass?",
    ans: "Yes, participants of our free masterclasses will receive a free ebook and additional resources to further explore the topic.",
  },
  {
    que: "Do I need any special equipment or software to participate in the classes?",
    ans: "For most classes, you just need a stable internet connection and a device to join the online sessions.",
  },
];

const dummyImagesForMobile = [
  "/main-website/mobile-reviews/ss5.png",
  "/main-website/mobile-reviews/ss6.png",
  "/main-website/mobile-reviews/ss7.png",
  "/main-website/mobile-reviews/ss8.png",
  "/main-website/mobile-reviews/ss9.png",
];

const categories = [
  {
    name: "Predictive Sciences",
    slug: "predictive-sciences",
    imgUrl: "/main-website/AstrolearnTarot.svg",
  },
  {
    name: "Healing & Manifestation",
    slug: "healing-&-manifestation",
    imgUrl: "/main-website/AstrolearnHoroscope.svg",
  },
];

export async function getServerSideProps() {
  try {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_BASE_API}masterclasses?limit=10&category=Spirituality`,
      {
        headers: {
          "Accept-Encoding": "gzip",
        },
      }
    );

    return {
      props: {
        masterclasses: res?.data?.data,
      },
    };
  } catch (err) {
    return {
      props: {
        masterclasses: [],
      },
    };
  }
}
export default function Home({ masterclasses }: { masterclasses: any }) {
  const router = useRouter();
  const [selectedQna, setSelectedQna] = useState(-1);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const _webengage = webengage();
    if (_webengage)
      _webengage.track("Homepage Viewed", {
        source: "Astrolearn",
      });

    mixpanel.track("home", {
      page_location: "Homepage",
    });
  }, []);

  // ----- For carousel -----
  const [currentIndex, setCurrentIndex] = useState(0);
  const filteredMasterclasses = masterclasses?.filter((mx: any) => {
    const found = mx?.slots?.filter((slot: any) => {
      return (
        slot?.active === true && slot.startDateTime >= dayjs().toISOString()
      );
    });
    return found.length > 0;
  });

  const coverImages: any = useKey(
    "astrolearn_main_website_home_page_carousel",
    {
      json: true,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % coverImages?.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [coverImages]);

  // --- Blogs Fetching
  useEffect(() => {
    const fetchBlogs = async () => {
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_BASE_API}blogs?live=true?limit=10`
      );
      setBlogs(response.data);
    };

    fetchBlogs();
  }, []);

  return (
    <Layout childClassName="bg-[#EEEBE6] pb-[20px] flex flex-col gap-10 md:gap-[72px] max-xl:p-4">
      <Head>
        <title>
          Best Online Astrology Course | Learn Live from Experts | Astrolearn
        </title>
        <meta
          name="description"
          content="Master astrology with live expert-led courses. Learn predictive sciences & healing, get certified, and unlock career opportunities today!"
        />
        <link
          rel="canonical"
          href="https://www.astrolearn.co"
          key="canonical"
        />
      </Head>

      <section className="md:px-8 relative overflow-hidden md:flex flex-row-reverse items-center justify-between lg:justify-center gap-[10%] md:min-h-[300px] md:py-10 -mt-4 w-full">
        <Carousel />
        <Image
          src="/home/hero-chakra.png"
          priority
          width={2180}
          height={2038}
          alt="decorative"
          className="absolute max-md:!w-[150%] max-md:!max-w-[150%] max-md:left-[-20%] top-[20%] md:top-[-100px] xl:top-[-600px] opacity-75 md:opacity-60 md:w-full md:mx-auto"
          style={{
            animation: "rotate 20s linear infinite",
          }}
        />
        <div>
          <div className="max-md:hidden flex justify-center md:justify-start items-center gap-2 max-md:mt-10">
            <Image src="/img/logo.png" alt="logo" width={35} height={35} />
            <p className="font-montserrat text-2xl">AstroLearn</p>
          </div>
          <h1 className="font-lato text-4xl font-bold max-w-[300px] lg:max-w-[450px] mx-auto text-center md:text-left max-md:mt-10 mt-4 mb-10">
            India’s Largest <br className="md:hidden" />
            Online <br className="md:hidden" /> Astro-Learning Platform
          </h1>
        </div>
      </section>

      {/* ----- Categories Section ----- */}
      <section className="flex flex-col items-center gap-6 justify-center text-black md:w-full -mt-10 md:-mt-20 md:px-8">
        <div className="flex gap-[9.6px]">
          <Image
            src="/designleft.svg"
            alt="decorative"
            width={50.5}
            height={16}
            className="md:w-[83px]"
          />
          <p className="text-4xl max-md:text-xl font-bold text-[#1A1A1A] font-lato">
            Our Categories
          </p>
          <Image
            src="/designright.svg"
            alt="decorative"
            width={50.5}
            height={16}
            className="md:w-[83px]"
          />
        </div>

        <motion.div
          className="w-full gap-4 xl:gap-8 flex items-center justify-center bg-[#EEEBE6]"
          transition={{ staggerChildren: 0.7, delay: 0.4 }}
        >
          {categories.map((category) => (
            <motion.span
              key={category.slug}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              viewport={{ once: true }}
              className="w-full max-w-96"
            >
              <Link
                href={`/masterclass?category=${category.slug.replace(
                  /&/g,
                  "%"
                )}`}
                className="hover:no-underline h-full"
                onClick={() => {
                  mixpanel.track("category_click", {
                    category: category.name,
                    button_location: "category_section",
                  });
                }}
              >
                <NewTagCard
                  onClick={() => {
                    const _webengage = webengage();
                    if (_webengage)
                      _webengage.track("Category Clicked", {
                        category: `${category.name}`,
                        source: "Astrolearn",
                      });
                  }}
                  headerText={category.name}
                  img={category.imgUrl}
                />
              </Link>
            </motion.span>
          ))}
        </motion.div>
      </section>

      {/* ----- Masterclasses ----- */}
      <section className="flex flex-col justify-center items-center gap-6 md:px-8">
        <div className="flex space-x-[9.6px]">
          <Image
            src="/designleft.svg"
            alt=""
            width={50.5}
            height={16}
            className="md:w-[83px]"
          />
          <p className="text-4xl max-md:text-lg font-lato mx-auto font-semibold text-nowrap">
            Explore Our Masterclasses
          </p>
          <Image
            src="/designright.svg"
            alt=""
            width={50.5}
            height={16}
            className="md:w-[83px]"
          />
        </div>

        <div className="flex items-center w-full h-[400px] overflow-x-scroll gap-5 no-scrollbar">
          {filteredMasterclasses?.map((masterclass: any) => {
            return <MasterclassCard masterclass={masterclass} />;
          })}
        </div>
        <button
          className="bg-[#0000000D] px-[5%] xl:px-[3%] mx-auto !rounded-md"
          onClick={() => {
            router.push("masterclasses");
            mixpanel.track("explore_masterclasses", {
              page_location: "Homepage",
              btn_location: "middle_cta",
            });
          }}
        >
          View All
        </button>
      </section>

      {/* ----- Our Promises ----- */}
      <section className="flex flex-col items-center gap-6 justify-center text-black w-full md:px-8">
        <div className="flex space-x-[9.6px]">
          <Image
            src="/designleft.svg"
            alt="decorative"
            width={50.5}
            height={16}
            className="md:w-[83px]"
          />
          <p className="text-4xl max-md:text-xl font-lato font-semibold">
            Our Promises
          </p>
          <Image
            src="/designright.svg"
            alt="decorative"
            width={50.5}
            height={16}
            className="md:w-[83px]"
          />
        </div>
        <p className="text-xl w-[700px] text-center hidden">
          Learn real life skills from Top Experts across the World. LIVE! Start
          your life long learning journey with us now.
        </p>
        <div className="flex flex-col lg:flex-row items-stretch space-y-[16px] md:space-y-[0] mt-[16px] md:gap-8 justify-center">
          <PromiseCard
            headerImage={"/main-website/index/computer.gif"}
            headerText={"100% Placement Guaranteed"}
            body={
              "We have successfully placed 1k plus Astrolearn experts with our best astrology courses."
            }
            className="bg-blend-lighten"
          />
          <PromiseCard
            headerImage={"/main-website/index/handShake.gif"}
            headerText={"Mentorship Programme"}
            body={
              "Get guidance in your professional life as a certified astrologer."
            }
            className=""
          />
          <PromiseCard
            headerImage={"/main-website/index/intractiveClass.gif"}
            headerText={"Interactive Live Classes"}
            body={
              "Understand concepts, ask questions and clear up your doubts."
            }
            className=""
          />
        </div>
      </section>

      {/* ----- Blogs ----- */}
      <section className="flex flex-col items-center justify-center gap-6 md:px-8">
        <div className="flex gap-3">
          <Image
            src="/designleft.svg"
            alt="decorative"
            width={50.5}
            height={16}
            className="md:w-[83px]"
          />
          <p className="text-4xl max-md:text-xl text-black font-lato font-semibold">
            Read Our Blogs
          </p>
          <Image
            src="/designright.svg"
            alt="decorative"
            width={50.5}
            height={16}
            className="md:w-[83px]"
          />
        </div>
        <div className="flex items-center space-x-4 md:space-x-6 max-md:overflow-x-auto max-md:w-full max-md:no-scrollbar">
          {blogs?.map((blog: any) => {
            return (
              <BlogPhotoTile
                imgSrc={blog.coverImage}
                title={
                  blog?.title && blog?.title.length > 18
                    ? blog?.title.slice(0, 18) + "..."
                    : blog?.title
                }
                minutesRead={calculateReadingTime(blog.content)}
                slug={blog.slug}
              />
            );
          })}
        </div>
        <button
          className="bg-[#0000000D] px-[5%] xl:px-[3%] mx-auto !rounded-md"
          onClick={() => {
            router.push("blog");
          }}
        >
          View All
        </button>
      </section>

      {/* ----- Community of Students ----- */}
      <motion.section
        transition={{ type: "spring", stiffness: 100, delay: 0.2 }}
        initial={{ bottom: -20, opacity: 0 }}
        whileInView={{
          bottom: 0,
          opacity: 1,
        }}
        viewport={{ once: true }}
        className="flex flex-col items-center justify-center gap-6 md:px-8"
      >
        <div className="flex items-center justify-center gap-3">
          <Image
            src="/designleft.svg"
            alt=""
            width={50.5}
            height={16}
            className="md:w-[83px]"
          />
          <p className="text-center text-xl md:text-4xl text-balance w-fit font-lato font-bold">
            Largest community of Students
          </p>
          <Image
            src="/designright.svg"
            alt=""
            width={50.5}
            height={16}
            className="md:w-[83px]"
          />
        </div>
        <div className="flex items-stretch justify-center bg-[#9A70261A] w-full md:w-full gap-4 rounded-lg py-5 md:gap-6 max-w-[1130px] mx-auto">
          <div className="flex flex-col items-center justify-center gap-[16px] md:rounded-xl w-full  md:py-3">
            <div className="flex flex-col items-center justify-center ">
              <p className="text-[24px] max-md:font-lato font-[600] md:text-[40px] xl:text-[54px]  ">
                5k +
              </p>
              <p className="text-[14px] md:text-[25px] xl:text-[36px] font-lato font-[400] md:font-[500]">
                Learners
              </p>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center gap-[16px]  md:rounded-xl w-full  md:py-3">
            <div className="flex flex-col items-center justify-center">
              <p className="text-[24px] max-md:font-lato font-[600] md:text-[40px] xl:text-[54px]  ">
                24+
              </p>
              <p className="text-[14px] md:text-[25px] xl:text-[36px] font-lato font-[400] md:font-[500]">
                Mentors
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-[16px] md:rounded-xl w-full md:py-3">
            <div className="flex flex-col items-center justify-center">
              <p className="text-[24px] max-md:font-lato font-[600] md:text-[40px] xl:text-[54px]  ">
                24k+
              </p>
              <p className="text-[14px] md:text-[25px] xl:text-[36px] font-lato font-[400] md:font-[500]">
                Happy Students
              </p>
            </div>
          </div>
        </div>
      </motion.section>

      {/* ----- Feedback ----- */}
      <section className="flex flex-col items-center justify-center gap-6 md:px-8">
        <div className="flex space-x-[9.6px]">
          <Image
            src="/designleft.svg"
            alt=""
            width={50.5}
            height={16}
            className="md:w-[83px]"
          />
          <p className="text-xl text-black text-center md:text-4xl font-lato font-semibold">
            What Our Students says
          </p>
          <Image
            src="/designright.svg"
            alt=""
            width={50.5}
            height={16}
            className="md:w-[83px]"
          />
        </div>
        <MobileCrousel images={dummyImagesForMobile} />
      </section>

      {/* ----- FAQ ----- */}
      <section className="flex flex-col justify-center items-center text-center mt-5 mb-[72px] gap-4 md:px-8">
        <div className="flex space-x-[9.6px]">
          <Image
            src="/designleft.svg"
            alt=""
            width={50.5}
            height={16}
            className="md:w-[83px]"
          />
          <p className="text-xl text-center font-semibold font-lato md:text-4xl">
            {" "}
            Frequently Asked Questions
          </p>
          <Image
            src="/designright.svg"
            alt=""
            width={50.5}
            height={16}
            className="md:w-[83px]"
          />
        </div>
        <div className="flex flex-col w-full max-w-[1130px] mx-auto">
          {qNAs.map((qna: { que: string; ans: string }, index: number) => {
            return (
              <div
                className="w-full rounded-xl"
                onClick={() => {
                  setSelectedQna((selectedQna) =>
                    selectedQna == index ? -1 : index
                  );
                }}
              >
                <QuestionAnswerTile
                  FaqQuestion={qna.que}
                  FaqAnswer={qna.ans}
                  className="!items-center !justify-between !text-start !text-base !w-full !pl-2"
                  isOpen={selectedQna == index}
                />
              </div>
            );
          })}
        </div>
      </section>
    </Layout>
  );
}
